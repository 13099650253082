


























































































































































import { Component, Prop } from "vue-property-decorator";
import { BaseForm } from "./BaseForm";

@Component({
  components: {},
})
export default class RegisterForm extends BaseForm {
  public labels: Record<string, string> = {
    firstName: "First Name",
    surName: "Last Name",
    mobileNo: "Mobile No",
    email: "Email",
    message: "Message",
  };

  @Prop({ required: true })
  public signinUrl!: string;

  public model: Record<string, any> = {
    firstName: null,
    surName: null,
    email: null,
    mobileNo: null,
    message: null,
  };

  public async send() {
    this.$emit("done", {});
  }
}
