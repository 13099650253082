
































































import { Component, Vue, Prop } from "vue-property-decorator";
import ContactForm from "./../components/forms/ContactForm.vue";

@Component({
  components: {
    ContactForm,
  },
  metaInfo: {
    title: "Contact Us",
  },
})
export default class Contact extends Vue {
  @Prop({ default: () => ({}) })
  public query!: Record<string, any>;

  onMessageSent() {
    return;
  }
}
